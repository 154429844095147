
<template>
  <Layout :tituloPagina="'Notificaciones | Redactar mensaje'">
    <div class="row">
      <div class="col-lg-9 col-12">
        <div class="mb-3">
          <div class="card">
            <h5 class="card-header bg-transparent border-bottom text-uppercase">
              Redactar mensaje
            </h5>
            <div class="card-body">
              <div class="row mb-4">
                <label for="receptor" class="col-sm-2 col-4 col-form-label">Para:</label>
                <div class="col-sm-6 col-8">
                  <select id="receptor" ref="receptor" class="form-select" v-model="mensaje.id_usuario_receptor">
                    <option :value="null">Seleccionar usuario</option>
                    <option 
                      v-for="usuario in usuarios"
                      :key="usuario.id"
                      :value="usuario.id"
                    >
                      {{usuario.nombre}}
                      {{usuario.apellido_paterno}}
                      {{usuario.apellido_materno}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-4">
                <label for="asunto" class="col-sm-2 col-4 col-form-label">Asunto:</label>
                <div class="col-sm-6 col-8">
                  <input
                    v-model="mensaje.asunto"
                    placeholder="Asunto del mensaje"
                    type="text" ref="asunto"
                    class="form-control" id="asunto"
                  />
                </div>
              </div>
            </div>
            <ckeditor
              v-model="mensaje.mensaje"
              :editor="editor"
              :config="editorConfig"
            ></ckeditor>
          </div>
          <div class="text-right">
            <div class="btn-group">
              <button
                class="btn btn-secondary"
                @click="cerrar()"
              >
                <i class="mdi mdi-chevron-left"></i> Atras
              </button>
              <button
                title="Enviar mensaje"
                class="btn btn-success"
                @click="enviarMensage()"
                :disabled="bandera_spinner"
              >
                <i 
                  class="mdi"
                  :class="!bandera_spinner ? 'mdi-check-bold' : 'mdi-concourse-ci mdi-spin'"
                ></i>
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import MensajeUsuarioSrv from '@/services/MensajeUsuarioSrv.js'
import UsuarioSrv from '@/services/UsuarioSrv.js'

import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import '@ckeditor/ckeditor5-build-classic/build/translations/es';

export default {
  name: 'RedactarMensaje',
  components: {
    Layout,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      modo: 'redactar',
      mensaje: {
        asunto: "",
        id_usuario_emisor: null,
        id_usuario_receptor: null,
        mensaje: "",
      },

      editor: ClassicEditor,
      editorConfig: {
        language: 'es',
        toolbar: {
          items: [
            'heading', '|',
            'alignment', '|',
            'bold', 'italic', '|',
            'link', '|',
            'bulletedList', 'numberedList', '|',
            'outdent', 'indent', '|',
            'blockQuote', '|',
            'insertTable', '|',
            // 'uploadImage',
            'mediaEmbed', '|',
            'undo', 'redo'
          ],
        },
        mediaEmbed: {
          previewsInData: true
        }
      },

      usuarios: [],
      bandera_spinner: false
    }
  },
  created: function() {
    var self = this

    if (self.$route.query.id_usuario_receptor) self.mensaje.id_usuario_receptor = self.$route.query.id_usuario_receptor

    self.cargarUsuarios()
  },
  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    enviarMensage(){
      var self = this

      self.bandera_spinner = true

      let mensaje = Object.assign(self.mensaje, {
        id_usuario_emisor: self.usuario.id
      })

      if(mensaje.id_usuario_receptor == null) {
        iu.msg.warning('Es necesario ingresar el usuario receptor')
        self.$refs.receptor.focus()
        self.bandera_spinner = false
        return
      }
      
      if(mensaje.asunto == null || mensaje.asunto == '') {
        iu.msg.warning('Es necesario ingresar un asunto del mensaje')
        self.$refs.asunto.select()
        self.bandera_spinner = false
        return
      }

      if(mensaje.mensaje == null || mensaje.mensaje == '') {
        iu.msg.warning('Es necesario ingresar el mensaje')
        self.bandera_spinner = false
        return
      }

      MensajeUsuarioSrv.guardar(self.mensaje).then(response => {
        iu.msg.success('Se ha enviado correctamente el mensaje')
        self.atras()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo enviar el mensaje '
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    cargarUsuarios: function() {
      var self = this

      let params = {}

      UsuarioSrv.usuariosJSON(params).then(response => {
        self.usuarios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    }
  },
  computed: {
    usuario() {
      return this.$store.state.todo.usuario
    }
  },
}
</script>

<style>

</style>